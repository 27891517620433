import React, { ReactNode, ReactEventHandler } from 'react';
import { CarouselNavigationButtonProps } from '../CarouselNavigationButton';
import { Container } from '../Container';
import { ReviewsCarousel, ReviewsCarouselProps } from '../ReviewsCarousel';
import { SectionHeading } from '../SectionHeading';
import { Typography } from '../Typography';
import { WaveBackground, Props as WaveBackgroundProps } from '../WaveBackground';
import { UnpackRecord } from '../../types/unpackRecord';
import { StyledSystemProps } from '../../types/styles';
import waveBackgroundDesktop from '../../assets/waves/vetted-dog-trainers-carousel-section-background-desktop.svg';
import waveBackgroundMobile from '../../assets/waves/vetted-dog-trainers-carousel-section-background-mobile.svg';
import waveBackgroundTablet from '../../assets/waves/vetted-dog-trainers-carousel-section-background-tablet.svg';
import { Flex } from '../../wag-react/next-components/Flex';
import { Button } from '../../wag-react/next-components/button';
type CarouselProps = {
  leftButtonProps: Omit<CarouselNavigationButtonProps, 'variant'>;
  rightButtonProps: Omit<CarouselNavigationButtonProps, 'variant'>;
};
type Props = Omit<WaveBackgroundProps, 'children'> & {
  bodyText?: ReactNode;
  carouselProps?: CarouselProps;
  ctaButtonText?: ReactNode;
  ctaButtonLink?: string;
  headingText: ReactNode;
  headingProps?: StyledSystemProps;
  reviews?: UnpackRecord<Pick<ReviewsCarouselProps, 'value'>>;
  onCtaButtonClick?: ReactEventHandler<HTMLButtonElement>;
} & StyledSystemProps;
export type ReviewsCarouselSectionProps = Props;
export const ReviewsCarouselSection = ({
  bodyText,
  carouselProps,
  ctaButtonLink,
  ctaButtonText = 'Book now',
  headingText,
  headingProps,
  reviews,
  onCtaButtonClick,
  ...rest
}: Props) => <WaveBackground backgroundImage={[`url(${waveBackgroundMobile})`, `url(${waveBackgroundTablet})`, `url(${waveBackgroundDesktop})`]} minHeight={['424px', '528px', '700px']} pt={['40px', '24px', '48px']} pb={['56px', '98px', '180px']} {...rest} data-sentry-element="WaveBackground" data-sentry-component="ReviewsCarouselSection" data-sentry-source-file="index.tsx">
    <Container data-sentry-element="Container" data-sentry-source-file="index.tsx">
      <SectionHeading color="white" textAlign="center" {...headingProps} data-sentry-element="SectionHeading" data-sentry-source-file="index.tsx">
        {headingText}
      </SectionHeading>
      <Typography color="white" maxWidth={['916px']} mb={0} mt="16px" mx="auto" textAlign="center" data-sentry-element="Typography" data-sentry-source-file="index.tsx">
        {bodyText}
      </Typography>
    </Container>
    <Container px={0} maxWidth="1200px" data-sentry-element="Container" data-sentry-source-file="index.tsx">
      <ReviewsCarousel mt={['32px', null, '40px']} value={reviews} {...carouselProps} data-sentry-element="ReviewsCarousel" data-sentry-source-file="index.tsx" />
      {(ctaButtonLink || onCtaButtonClick) && <Flex justifyContent="center" alignItems="center" mt={['40px', '64px']}>
          <Button href={ctaButtonLink} onClick={onCtaButtonClick} variant="contained" backgroundColor="white" color="wagGreen" size="extraLarge" width={['272px', '336px']}>
            {ctaButtonText}
          </Button>
        </Flex>}
    </Container>
  </WaveBackground>;