import React, { ReactNode } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Navigation } from 'swiper';
import { createGlobalStyle } from 'styled-components';
import { ReviewCard } from '../ReviewCard';
import { ReviewCardAvatar } from '../ReviewCardAvatar';
import { ReviewCardLocation } from '../ReviewCardLocation';
import { ReviewCardNameText } from '../ReviewCardNameText';
import { ReviewCardRating } from '../ReviewCardRating';
import { ReviewCardReviewText } from '../ReviewCardReviewText';
import { CarouselNavigationButton } from '../CarouselNavigationButton';
import { useMediaQuery, useTheme } from '../../hooks';
import { reviewsData } from '../../data';
import { getRandomSilhouetteName } from '../../utils';
import { StyledSystemProps } from '../../types/styles';
import { pxToNumber } from '../../wag-react/common/utils/pxToNumber';
import { Box, BoxProps } from '../../wag-react/next-components/box';
SwiperCore.use([Navigation]);
export type Review = {
  rating: number;
  name: string;
  gender?: string;
  review: string;
  src?: string;
  location?: string;
};
export type CarouselButtonProps = {
  Icon?: ReactNode;
} & StyledSystemProps;
export type Props = Omit<BoxProps, 'value'> & {
  value?: Review[];
  leftButtonProps?: CarouselButtonProps;
  rightButtonProps?: CarouselButtonProps;
};
export type ReviewsCarouselProps = Props;
export const ReviewsCarousel = ({
  value = reviewsData,
  leftButtonProps,
  rightButtonProps,
  ...rest
}: Props) => {
  const {
    theme
  } = useTheme();
  const smBreakpoint = pxToNumber(theme.breakpoints[0]);
  const {
    matched: isXsDown
  } = useMediaQuery({
    maxWidth: smBreakpoint - 1
  });
  const swiperProps = {
    breakpoints: {
      0: {
        // When window width is >= 0px
        freeMode: true,
        slidesOffsetBefore: 16,
        spaceBetween: 16,
        width: 264
      },
      [smBreakpoint]: {
        // When window width is >= 640px
        slidesPerView: 2
      },
      1024: {
        // When window width is >= 1024px
        slidesPerView: 3
      }
    },
    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev'
    },
    grabCursor: true,
    // loop: true,
    // loopFillGroupWithBlank: true,
    spaceBetween: 16,
    parallax: true
  };
  return <>
      <Box position="relative" px={[0, '48px', '56px']} {...rest} data-sentry-element="Box" data-sentry-source-file="index.tsx">
        {/*
          We can't create a component for the "children" of the <Swiper />
          for some reason with its internal code. Whenever we create
          a component and render it on Swiper's children,
          the CSS classes aren't getting applied.
          In order to work on top of that, we have to iterate here directly
         */}
        <CarouselNavigationButton variant="prev" {...leftButtonProps} data-sentry-element="CarouselNavigationButton" data-sentry-source-file="index.tsx" />
        <CarouselNavigationButton variant="next" {...rightButtonProps} data-sentry-element="CarouselNavigationButton" data-sentry-source-file="index.tsx" />
        <Swiper navigation={{
        nextEl: isXsDown ? undefined : '.custom_next',
        prevEl: isXsDown ? undefined : '.custom_prev'
      }} {...swiperProps} data-sentry-element="Swiper" data-sentry-source-file="index.tsx">
          {value.map(({
          gender,
          name,
          rating,
          review,
          src,
          location
        }) => <SwiperSlide key={name}>
              <ReviewCardAvatar icon={gender && getRandomSilhouetteName({
            gender
          })} src={src} alt={`${name} avatar`} />
              <ReviewCard>
                <ReviewCardRating value={rating} />
                <ReviewCardNameText>{name}</ReviewCardNameText>
                <ReviewCardLocation>{location}</ReviewCardLocation>
                <ReviewCardReviewText>{review}</ReviewCardReviewText>
              </ReviewCard>
            </SwiperSlide>)}
        </Swiper>
        {!isXsDown && <Box className="swiper-button-prev" height="auto" id="swiper-button-prev" left={[0, 0, '8px']} width="auto" />}
        {!isXsDown && <Box className="swiper-button-next" height="auto" id="swiper-button-next" right={[0, 0, '8px']} width="auto" />}
      </Box>
      <SwiperStyles data-sentry-element="SwiperStyles" data-sentry-source-file="index.tsx" />
    </>;
};
const SwiperStyles = createGlobalStyle`
  .swiper-button-prev:after {
    display: none;
  }

  .swiper-button-next:after {
    display: none;
  }
`;