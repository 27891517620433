import React from 'react';
import { SvgIcon, SvgIconProps } from '../wag-react/next-components/icons';
export type ChevronRightOutlinedIconProps = SvgIconProps & {
  circleProps?: JSX.IntrinsicElements['circle'];
};
export const ChevronRightOutlinedIcon = ({
  circleProps,
  ...rest
}: ChevronRightOutlinedIconProps) => <SvgIcon viewBox="0 0 38 38" {...rest} data-sentry-element="SvgIcon" data-sentry-component="ChevronRightOutlinedIcon" data-sentry-source-file="ChevronRightOutlinedIcon.tsx">
    <circle stroke="currentColor" strokeWidth="2" fill="transparent" cx="19" cy="19" r="18" {...circleProps} data-sentry-element="circle" data-sentry-source-file="ChevronRightOutlinedIcon.tsx" />
    <path d="M17.267 26.6c-.756-.002-1.436-.443-1.725-1.117a1.76 1.76 0 0 1 .403-1.966l4.29-4.148-4.29-4.14a1.76 1.76 0 0 1 .056-2.497c.707-.684 1.846-.71 2.587-.06l5.62 5.423a1.77 1.77 0 0 1 .552 1.279 1.77 1.77 0 0 1-.552 1.279l-5.62 5.423a1.91 1.91 0 0 1-1.321.526" fill="currentColor" fillRule="nonzero" data-sentry-element="path" data-sentry-source-file="ChevronRightOutlinedIcon.tsx" />
  </SvgIcon>;