import petAvatar01 from '../assets/icons/dog-avatar-1.jpg';
import petAvatar02 from '../assets/icons/dog-avatar-2.jpg';
import petAvatar03 from '../assets/icons/dog-avatar-3.jpg';
import petAvatar04 from '../assets/icons/dog-avatar-4.jpg';
import petAvatar05 from '../assets/icons/dog-avatar-5.jpg';
import petAvatar06 from '../assets/icons/dog-avatar-6.jpg';

export const reviewsData = [
  {
    rating: 5,
    name: 'Brenda S',
    src: petAvatar01,
    review:
      'Wag! gives me great peace of mind while I am at work. I get to the minute potty information and a picture of his smile at the end of walk. Thank you Wag!',
  },
  {
    rating: 5,
    name: 'Slow C',
    src: petAvatar02,
    review:
      'Great app for hiring a dog walker! I love that I can track where my dog is, how many miles she walked, when she potties, etc. She comes back very happy!',
  },
  {
    rating: 5,
    name: 'Brandon A',
    src: petAvatar03,
    review:
      'This is the best thing for my dog and I. He loves getting to meet new people and going on long walks. I\'m not worried about him not getting enough exercise!',
  },
  {
    rating: 5,
    name: 'Tanya K',
    src: petAvatar04,
    review:
      'All the Wag! walks I’ve gotten have been awesome. Sweet and caring people who obviously love dogs!',
  },
  {
    rating: 5,
    name: 'Eve R',
    src: petAvatar05,
    review:
      "What a great experience! I'm sure Vienna was happy to go outside and stretch her legs.",
  },
  {
    rating: 5,
    name: 'Ashlee A',
    src: petAvatar06,
    review:
      "I've been using Wag! for about 6 months and love the convenience of being able to have someone take care of my pup when I'm gone.",
  },
];
