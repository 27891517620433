'use client';

import React from 'react';
import { ChevronLeftOutlinedIcon, ChevronLeftOutlinedIconProps } from '../../icons/ChevronLeftOutlinedIcon';
import { ChevronRightOutlinedIcon, ChevronRightOutlinedIconProps } from '../../icons/ChevronRightOutlinedIcon';
import { executionEnvironment } from '../../wag-react/common/xenv';
import { Portal } from '../../wag-react/components/Portal';
import { BoxProps } from '../../wag-react/next-components/box';
import { IconButton, IconButtonProps } from '../../wag-react/next-components/button/IconButton';

/**
 * This technique is called conditional React props (from discriminated union)
 * For the conditional React props to happen there should be a discriminated union
 * The discriminated union in this typing is the "variant" prop.
 * In other words, variant is the common prop on both typing (both required and same type value)
 * The variant prop is important because it is what TypeScript is using
 * on whether to use the first typing or the second typing.
 *
 * If variant is "next", nextContainerElemId is optional
 *  and prevContainerElemId shouldn't have any value
 *
 * If variant is "prev", prevContainerElemId is optional
 *  and nextContainerElemId shouldn't have any value
 */
type CarouselNavigationButtonVariant = {
  variant: 'next';
  nextContainerElemId?: string;
  prevContainerElemId?: never;
  icon?: (props: ChevronRightOutlinedIconProps) => JSX.Element;
} | {
  variant: 'prev';
  prevContainerElemId?: string;
  nextContainerElemId?: never;
  icon?: (props: ChevronRightOutlinedIconProps) => JSX.Element;
};
export type CarouselNavigationButtonProps = CarouselNavigationButtonVariant & {
  iconProps?: ChevronLeftOutlinedIconProps & ChevronRightOutlinedIconProps;
} & IconButtonProps & BoxProps;
export const CarouselNavigationButton = ({
  variant,
  iconProps,
  prevContainerElemId = 'swiper-button-prev',
  nextContainerElemId = 'swiper-button-next',
  icon,
  ...rest
}: CarouselNavigationButtonProps) => {
  const Component = variant === 'prev' ? icon || ChevronLeftOutlinedIcon : icon || ChevronRightOutlinedIcon;
  const id = variant === 'prev' ? prevContainerElemId : nextContainerElemId;
  if (executionEnvironment.isSSR) {
    return null;
  }
  const containerEl = document.getElementById(id);
  if (!containerEl) {
    return null;
  }
  return <Portal container={containerEl} data-sentry-element="Portal" data-sentry-component="CarouselNavigationButton" data-sentry-source-file="index.tsx">
      <IconButton height="auto" p={0} width="auto" {...rest} data-sentry-element="IconButton" data-sentry-source-file="index.tsx">
        <Component fontSize={['24px', '36px']} color="white" {...iconProps} data-sentry-element="Component" data-sentry-source-file="index.tsx" />
      </IconButton>
    </Portal>;
};