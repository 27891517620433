import React, { FunctionComponent, MouseEvent } from 'react';
import styled from 'styled-components';
import { Box, BoxProps } from '../box';

/**
 * ! TODO: support `size` prop? need to coordinate with design.
 */
type Props = Omit<BoxProps, 'onClick'> & Pick<JSX.IntrinsicElements['button'], 'disabled' | 'type' | 'onClick'>;
export type IconButtonProps = Props;
const IconButton = ({
  children,
  disabled,
  onClick,
  ...rest
}: Props) => <Box as="button" alignItems="center" background="none" border="none" borderRadius="50%" cursor="pointer" disabled={disabled} display="inline-flex" fontSize="24px" fontFamily="muli" justifyContent="center" outline="none" p="12px" textDecoration="none"
// optionally add `onClick` event handler
{...onClick && {
  onClick: (event: unknown) => {
    onClick(event as MouseEvent<HTMLButtonElement>);
  }
}} {...rest} data-sentry-element="Box" data-sentry-component="IconButton" data-sentry-source-file="IconButton.tsx">
    <Box as="span" color="inherit" cursor="inherit" display="flex" fontFamily="inherit" fontSize="inherit" fontWeight="inherit" lineHeight="inherit" data-sentry-element="Box" data-sentry-source-file="IconButton.tsx">
      {children}
    </Box>
  </Box>;
const StyledIconButton = styled(IconButton)<Props>`
  &:hover {
    opacity: 0.8;
  }

  &:active {
    opacity: 1;
  }

  &:disabled {
    cursor: not-allowed;
    pointer-events: none;
  }

  &:disabled {
    opacity: 0.26;
  }
` as unknown as FunctionComponent<React.PropsWithChildren<Props>>;
export { StyledIconButton as IconButton };