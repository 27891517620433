import React from 'react';
import { StyledSystemProps } from '../../types';
import Avatar from '../../wag-react/components/Avatar';
type Props = {
  icon?: string;
  src?: string;
  alt?: string;
} & StyledSystemProps;
export type ReviewCardAvatarProps = Props;
export const ReviewCardAvatar = ({
  icon,
  src,
  alt,
  ...rest
}: Props) => <Avatar bg="#d4d4d4" containerProps={{
  display: 'block',
  mx: 'auto',
  position: 'relative'
}} height={['56px', '64px', '72px']} icon={icon} src={src} iconHeight={['56px', '64px', '72px']} iconWidth={['56px', '64px', '72px']} width={['56px', '64px', '72px']} imageProps={{
  alt
}} {...rest} data-sentry-element="Avatar" data-sentry-component="ReviewCardAvatar" data-sentry-source-file="index.tsx" />;